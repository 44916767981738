<template>
  <div class="col-lg-4 mb-lg-0 align-self-end">
    <b-button
      v-if="checkPermission('create paket talenta jagoan')"
      v-b-modal.form-modal
      class="btn btn-info btn-sm"
      data-toggle="tooltip"
      data-placement="top"
      title="Add Slider"
      @click="cleanUpForm"
    >
      <feather-icon
        icon="PlusCircleIcon"
      />
      Tambah Paket Talenta Jagoan
    </b-button>

    <b-modal
      id="form-modal"
      no-close-on-backdrop
      size="lg"
      title="Tambah Paket Talenta Jagoan"
    >
      <div class="form">
        <h5>Paket Talenta Jagoan</h5>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Nama</label>
              <validation-provider>
                <b-form-input
                  v-model="formPaket.name"
                  :state="validationsPaket.name ? false:null"
                  placeholder="Nama"
                />
                <small
                  v-for="(validation, index) in validationsPaket.name"
                  :key="`errorName${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Harga:</label>
              <validation-provider>
                <b-input-group
                  prepend="Rp"
                >
                  <money
                    v-model="formPaket.price"
                    placeholder="Harga"
                    class="form-control"
                    v-bind="money"
                  />
                </b-input-group>
                <small
                  v-for="(validation, index) in validationsPaket.price"
                  :key="`errorPrice${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Harga Lama:</label>
              <validation-provider>
                <b-input-group
                  prepend="Rp"
                >
                  <money
                    v-model="formPaket.old_price"
                    placeholder="Harga Lama"
                    class="form-control"
                    v-bind="money"
                  />
                </b-input-group>
                <small
                  v-for="(validation, index) in validationsPaket.old_price"
                  :key="`errorOldPrice${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Masa Aktif (hari)</label>
              <validation-provider>
                <b-form-input
                  v-model="formPaket.active_days"
                  type="number"
                  :state="validationsPaket.active_days ? false:null"
                  placeholder="Masa Aktif (hari)"
                />
                <small
                  v-for="(validation, index) in validationsPaket.active_days"
                  :key="`erroractive_days${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Gratis Kredit Pital Ads:</label>
              <validation-provider>
                <b-input-group
                  prepend="Rp"
                >
                  <money
                    v-model="formPaket.free_pitals_ads_credit"
                    placeholder="Gratis Kredit Pital Ads"
                    class="form-control"
                    v-bind="money"
                  />
                </b-input-group>
                <small
                  v-for="(validation, index) in validationsPaket.free_pitals_ads_credit"
                  :key="`errorfree_pitals_ads_credit${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Jumlah Akun Karyawan Gratis</label>
              <validation-provider>
                <b-form-input
                  v-model="formPaket.free_teams_slot"
                  type="number"
                  :state="validationsPaket.free_teams_slot ? false:null"
                  placeholder="Jumlah Akun Karyawan Gratis"
                />
                <small
                  v-for="(validation, index) in validationsPaket.free_teams_slot"
                  :key="`errorTimSlot${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="col-lg-12 mb-1">
            <quill-editor
              v-model="formPaket.description"
            />
          </div>
        </div>
      </div>

      <template #modal-footer="{}">
        <div v-if="isLoadingCategory">
          <br>
          <b-spinner
            class="mb-2"
            variant="primary"
          /><br>
        </div>
        <b-button
          variant="success"
          :disabled="isLoadingCategory"
          @click="editId == null ? createItem() : updateItem()"
        >
          Simpan
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import {
  BButton, BSpinner, BFormInput, BInputGroup,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import { Money } from 'v-money'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BButton,
    BSpinner,
    ValidationProvider,
    BFormInput,
    quillEditor,
    BInputGroup,
    Money,
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    formPayload: {
      type: '',
    },
    isLoading: {
      type: Boolean,
    },
    editId: {
      type: String,
    },
    createItem: {
      type: Function,
    },
    updateItem: {
      type: Function,
    },
    validations: {
      type: '',
    },
    cleanUpForm: {
      type: Function,
    },
    parent: {
      type: Object,
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      parents: [],
      isLoadingCategory: false,
      formPaket: {
        name: '',
        old_price: '',
        price: '',
        active_days: '',
        description: '',
        free_pitals_ads_credit: '',
        free_teams_slot: '',
      },
      validationsPaket: '',
      money: {
        decimal: '',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false,
      },
    }
  },
  watch: {
    isLoading(value) {
      this.isLoadingCategory = value
    },
    validations(value) {
      this.validationsPaket = value
    },
    formPayload: {
      handler(value) {
        this.formPaket = value
      },
      deep: true,
    },
    formPaket: {
      handler(value) {
        this.$emit('payload', value)
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
